<template>
  <div class="router-view-style">
    <router-view/>
  </div>
  
</template>

<style lang="scss">
.router-view-style{
  height: 100vh;
  width: 100vw;
}
</style>
